/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */
// jshint ignore: start
(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

  document.addEventListener( 'wpcf7mailsent', function( event ) {
    $('.wpcf7-submit').addClass('uk-success').val('SENT! ✔');
  }, false );


  var count = 2;
  var total = $('.uk-loader').data('total');
  var loading = false;

  function loadArticle(pageNumber) {
    $('.uk-loader').addClass('uk-active');
    $.ajax({
      method: 'post',
      data: {
        action: 'ajax_pagination',
        num: pageNumber ,
      },
      dataType: 'html',
      url: ajax_url,
      success: function (html) {
        loading = false;
        $('.uk-loader').removeClass('uk-active');
        $(html).appendTo('.uk-ajax-block');
      }
    });
    return false;
  }

  $(window).scroll(function () {
    if($('body .uk-loader').length) {
      var offset = $('body .uk-loader').offset().top - $(window).height()/2;
      console.log($(window).scrollTop() +'-'+ offset );
      if(!loading) {
        if($(window).scrollTop() > offset){
          loading = true;
          if (count > total) {
            return false;
          } else {
            loadArticle(count);
          }
          count++;
        }
      }
    }
  });

  jQuery('.wpcf7-submit').click(function () {
    if(jQuery('.email-confirm').length) {
      // We remove the error to avoid duplicate errors
      jQuery('.error').remove();
      // We create a variable to store our error message
      var errorMsg = jQuery('<span class="wpcf7-not-valid-tip">Your emails do not match.</span>');
      // Then we check our values to see if they match
      // If they do not match we display the error and we do not allow form to submit
      if (jQuery('.email').find('input').val() !== jQuery('.email-confirm').find('input').val()) {
        errorMsg.insertAfter(jQuery('.email-confirm').find('input'));
        return false;
      } else {
        // If they do match we remove the error and we submit the form
        jQuery('.error').remove();
        return true;
      }
    }
  });


})(jQuery); // Fully reference jQuery after this point.